export const Style = {
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    background: "#20353A",
    borderRadius: "100%",
  },
};
