import { configureStore } from "@reduxjs/toolkit";
import {
  user,
  slides,
  countries,
  events,
  categories,
  loading,
  tickets,
  favourite,
  pages,
} from "./slice";
export const store = configureStore({
  reducer: {
    user,
    slides,
    countries,
    events,
    categories,
    loading,
    tickets,
    favourite,
    pages,
  },
});
