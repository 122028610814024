import Logo from "../assets/logo.png";
import DummyCountry from "../assets/dummyCountry.png";
import CarouselItem from "../assets/carouselItem.png";
import games from "../assets/games.png";
import cardImage from "../assets/cardImage.png";
import ticketDate from "../assets/ticketDate.png";
import whatsappIcon from "../assets/whatsappIcon.png";
import miniCardImage1 from "../assets/miniCardImage1.png";
import miniCardImage2 from "../assets/miniCardImage2.png";
import miniCardImage3 from "../assets/miniCardImage3.png";
import miniCardImage4 from "../assets/miniCardImage4.png";
import miniCardImage5 from "../assets/miniCardImage5.png";
import miniCardImage6 from "../assets/miniCardImage6.png";
import goldenTicket from "../assets/goldenTicket.png";
import silverTicket from "../assets/silverTicket.png";
import greenTicket from "../assets/greenTicket.png";
import failure from "../assets/Failure.png";
import success from "../assets/Success.png";
import noTickets from "../assets/noTickets.png";
import ticketPreview from "../assets/ticketPreview.png";
import ticketBlackBorder from "../assets/ticketBlackBorder.png";
import ticketBgOrange from "../assets/ticketBgOrange.png";
import ticketBgWhite from "../assets/ticketBgWhite.png";
import QR from "../assets/QR.png";
import visa from "../assets/visa.png";
import knet from "../assets/knet.png";
import xbox from "../assets/xbox.png";
import whiteTag from "../assets/whiteTag.png";
import googlePay from "../assets/googlePay.png";
import applePay from "../assets/applePay.png";

const IMAGES = {
  Logo,
  DummyCountry,
  CarouselItem,
  games,
  cardImage,
  ticketDate,
  whatsappIcon,
  miniCardImage1,
  miniCardImage2,
  miniCardImage3,
  miniCardImage4,
  miniCardImage5,
  miniCardImage6,
  ticketBgOrange,
  ticketBgWhite,
  goldenTicket,
  silverTicket,
  greenTicket,
  failure,
  success,
  noTickets,
  ticketPreview,
  ticketBlackBorder,
  whiteTag,
  QR,
  visa,
  knet,
  xbox,
  googlePay,
  applePay,
};
export { IMAGES };
