import { COLORS, IMAGES } from "../../theme";

export const Style = {
  main: {
    backgroundImage: `url('${IMAGES.ticketDate}')`,
    position: "absolute",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    color: COLORS.black,
    textAlign: "center",
    padding: "0 !important",
  },
};
