import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { CustomCard, CustomCarousel } from "../../components";
import { getCountryCategoryEvents } from "../../services/data/events";
import { selectCategories } from "../../store/slice/categories";
import { selectEventsData } from "../../store/slice/events";
import { selectLoading } from "../../store/slice/loading";
import { selectUser } from "../../store/slice/user";
import { handleCategorySelect } from "../../utils";
import { Style } from "./style";

const Home = () => {
  const [goToSlide, setGoToSlide] = useState(false);
  const dispatch = useDispatch();
  const { next_page_url, current_page, data, searchKey } =
    useSelector(selectEventsData);
  const { loader } = useSelector(selectLoading);
  const user = useSelector(selectUser);
  const categories = useSelector(selectCategories);
  const { language, countryId, category } = useSelector(selectUser);
  const [eventsData, setEventsData] = useState(data);
  const [hasMore, setHasMore] = useState(!!next_page_url);
  const [nextPage, setNextPage] = useState(current_page + 1);
  const scrollAbleDivRef = useRef(null);
  const [isEndReach, setIsEndReach] = useState(false);
  const handleScroll = () => {
    if (window.innerWidth > 600) return;
    const scrollLeft = scrollAbleDivRef.current.scrollLeft;
    let windowWidth = window.innerWidth;
    if (!isEndReach) {
      setIsEndReach(
        scrollLeft + 1000 > windowWidth * (eventsData.length * 0.85)
      );
      if (scrollLeft + 1000 > windowWidth * (eventsData.length * 0.85))
        fetchMoreData();
    }
  };
  let skeletonArray = new Array(1, 2, 3, 4, 5, 6, 7, 8);
  const fetchMoreData = async () => {
    try {
      const events = await getCountryCategoryEvents({
        page: nextPage,
        number: 12,
        country: countryId,
        category: category?.id,
        searchKey,
      });
      setEventsData((prev) => [...prev, ...events.data.data.data]);
      setHasMore(events.data.data.next_page_url);
      setNextPage((prev) => prev + 1);
      setIsEndReach(false);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    setEventsData(data);
    setNextPage(current_page + 1);
    setHasMore(!!next_page_url);
  }, [data]);
  useEffect(() => {
    mixpanel?.track("home", {
      email: user?.email,
      name: user?.name || "anonymous",
    });
  }, []);
  const isArabic = language === "ar";
  return (
    <Box sx={Style.main}>
      <CustomCarousel />
      <Box sx={Style.smallScreenOptionsContainer(isArabic)}>
        {categories?.map((item, index) => (
          <Typography
            key={index}
            component={"span"}
            sx={Style.smallScreenOptions(category?.id, item?.id, isArabic)}
            onClick={() =>
              handleCategorySelect(item, searchKey, dispatch, countryId)
            }
          >
            {item?.translation[language]?.name}
          </Typography>
        ))}
      </Box>

      {/* small screen card */}
      {/* <Box display={{ xs: "flex", sm: "none" }}>
        <Carroussel
          cards={eventsData?. map((item, index) => ({
            key: index,
            content: (
              <Card
                setGoToSlide={setGoToSlide}
                key={index}
                item={item}
                index={index}
                goToSlide={goToSlide}
              />
            ),
          }))}
          goToSlide={goToSlide}
          setGoToSlide={setGoToSlide}
          height="480px"
          width="100%"
          margin="40px auto 50px"
          offset={2}
          showArrows={false}
        />
      </Box> */}

      {/* large screen card */}
      <InfiniteScroll
        dataLength={eventsData.length}
        next={() => {
          if (window.innerWidth >= 600) {
            fetchMoreData();
          }
        }}
        hasMore={hasMore}
        loader={
          window.innerWidth >= 600 && (
            <Box sx={{ textAlign: "center", marginY: 5 }}>
              <CircularProgress size={50} color="secondary" />
            </Box>
          )
        }
        style={{ overflow: "hidden" }}
      >
        <Box
          sx={{
            ...Style.customCardContainerBox,
            overflow: loader ? "hidden" : "auto",
          }}
          ref={scrollAbleDivRef}
          onScroll={handleScroll}
        >
          <Box
            sx={Style.customCardContainer(
              !loader ? eventsData?.length : skeletonArray?.length * 1,
              !loader,
              isArabic
            )}
          >
            {!loader
              ? eventsData.map((item, index) => (
                  <CustomCard
                    length={eventsData?.length}
                    key={index}
                    data={item}
                  />
                ))
              : skeletonArray.map(() => {
                  return (
                    <Skeleton variant="rectangular" sx={Style.cardSkeleton} />
                  );
                })}
          </Box>
        </Box>
      </InfiniteScroll>
    </Box>
  );
};

export default Home;
