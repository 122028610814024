import axios from "axios";

const getAllCategories = async (props) => {
  const { page = 1, number = 12 } = props;
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/categories?page=${page}&number=${number}`,
  });
};
export { getAllCategories };
