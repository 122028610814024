import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomCard } from "../../components";
import { getFavouriteEvents } from "../../services/favourite";
import { getFavourite, selectFavourite } from "../../store/slice/favourite";
import { selectUser } from "../../store/slice/user";
import { Style } from "./style";
import { useTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";

const MyFavorites = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const user = useSelector(selectUser);
  const favouriteEvents = useSelector(selectFavourite);
  const { token, language } = useSelector(selectUser);
  const scrollAbleDivRef = useRef(null);
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const scrollLeft = scrollAbleDivRef.current.scrollLeft;
    setScrollPosition(scrollLeft);
  };
  let skeletonArray = new Array(1, 2, 3, 4, 5, 6, 7, 8);
  const handleFavouriteEventsFetch = async () => {
    try {
      const { data } = await getFavouriteEvents(token);
      dispatch(getFavourite(data?.data));
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  useEffect(() => {
    handleFavouriteEventsFetch();
    mixpanel?.track("my-favorites", {
      email: user?.email,
      name: user?.name || "anonymous",
    });
  }, []);
  const isArabic = language === "ar";
  return (
    <Box sx={Style.main}>
      <Typography
        sx={{
          color: "#fff",
          width: "calc(100% - 40px)",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          position: "relative",
          m: { xs: "10px 0", md: "30px 0 20px" },
        }}
      >
        <Typography
          component={"span"}
          sx={{
            position: "relative",
            display: { xs: "flex", md: "none" },
            position: "absolute",
            top: "0px",
            left: 0,
          }}
        >
          <ArrowBackIcon
            onClick={() => navigate("/")}
            style={{
              ...Style.icon,
              fontSize: "30px",
            }}
          />
        </Typography>
        <Typography component={"span"} sx={Style.heading(isArabic)}>
          {t("drawer.my_favorites")}
        </Typography>
      </Typography>
      <Box
        sx={Style.customCardContainerBox}
        ref={scrollAbleDivRef}
        onScroll={handleScroll}
      >
        <Box sx={Style.customCardContainer(favouriteEvents?.length, isArabic)}>
          {!loader
            ? favouriteEvents?.map((item, index) => {
                return (
                  <CustomCard
                    sx={Style.customCard}
                    childSx={Style.customCardBox}
                    key={index}
                    data={item}
                    favoritePage={true}
                  />
                );
              })
            : skeletonArray.map((item, index) => {
                return (
                  <Skeleton variant="rectangular" sx={Style.cardSkeleton} />
                );
              })}
        </Box>
      </Box>
    </Box>
  );
};

export default MyFavorites;
