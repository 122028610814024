import { COLORS, IMAGES } from "../../theme";

const Style = {
  eventDetail: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    padding: { xs: "0", md: "25px 24px" },
    justifyContent: "center",
    width: { xs: "100%", md: "fit-content" },
    margin: "0 auto",
    gap: { xs: "0", md: "40px" },
    boxSizing: "border-box",
    maxWidth: "100vw",
  }),
  eventDetailScrollBox: {
    width: { xs: "100%", md: "calc(50vw - 53px)" },
    maxWidth: "636px",
    boxSizing: "border-box",
    padding: { xs: "0", md: 0 },
    paddingBottom: { xs: "60px", sm: "65px", md: "10px" },
    overflowX: "hidden",
    height: { xs: "100vh", md: `calc(100vh - 130px)` },
    overflowY: "scroll",
    "&::-webkit-scrollbar": { width: 0 },
  },
  ImageBox: {
    mt: "15px",
    position: "relative",
    width: "100%",
    height: { xs: "290px", sm: "300px", md: "400px" },
    borderRadius: "20px",
    overflow: "hidden",
  },
  Image: {
    flexShrink: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: { xs: "0 20%", sm: "0 20%", md: "0 19.5%" },
  },
  grayscale: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: {
      xs: `linear-gradient(180deg, rgba(6, 11, 12, 0.00) 0%, #060B0C 100%)`,
      md: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #20353A 100%)`,
    },
  },
  hiddenLabelBox: (isArabic) => ({
    position: "absolute",
    display: { xs: "flex", md: "none" },
    justifyContent: isArabic ? "end" : "start",
    textAlign: isArabic ? "end" : "start",
    bottom: "15px",
    left: 0,
    width: "100%",
    height: "175px",
    padding: "10px",
    boxSizing: "border-box",
    color: "#fff",
  }),
  whiteTag: (isArabic) => ({
    backgroundImage: `url(${IMAGES.whiteTag})`,
    right: isArabic ? "none" : { xs: "15px", md: "30px" },
    left: isArabic ? { xs: "15px", md: "30px" } : "none",
    width: "60px",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px 0",
  }),
  whiteTagDate: (isArabic) => ({
    color: "var(--Secondary, #20353A)",
    textAlign: "center",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "normal",
  }),
  whiteTagMonth: (isArabic) => ({
    color: "var(--Secondary, #20353A)",
    textAlign: "center",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "1.3",
  }),
  hiddenTypoContainer: {
    minWidth: "120px",
    maxWidth: "75%",
    // background: 'aqua'
  },
  musicalTypo: (isArabic) => ({
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  }),
  hiddenTypoHeading: (isArabic) => ({
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "21px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  }),
  locationContainer: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    marginTop: "10px",
    display: "flex",
    fontSize: "25px",
    alignItems: "center",
    justifyContent: isArabic ? "end" : "start",
    flexDirection: isArabic ? "row-reverse" : "row",
    gap: "5px",
  }),
  hiddenLocation: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif !important" : "Inter",
    color: "var(--Gray, #B4B6B6)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    width: "calc(100% - 40px)",
  }),
  playIcon: {
    width: "20px",
    height: "20px",
    borderRadius: "20px",
    flexShrink: "0",
    marginLeft: "5px",
    color: "white",
    padding: "8px",
    background: "rgba(0, 0, 0, 0.5)",
  },
  typoContainer: {
    padding: { xs: "15px", md: 0 },
  },
  miniCardsContainer: (isArabic) => ({
    py: "10px",
    overflowY: "hidden",
    overflowX: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: isArabic ? "end" : "start",
    justifyContent: isArabic ? "end" : "start",
  }),
  miniCards: (isArabic, length) => ({
    margin: "12px 0",
    display: "flex",
    width: length >= 6 ? "636px" : `${106 * length - 5}px`,
    gap: "12px",
    flexDirection: isArabic ? "row-reverse" : "row",
    scrollbarWidth: { xs: "none", md: "auto" },
  }),
  miniCardImg: (src, isArabic) => ({
    backgroundImage: `url(${src})`,
    color: "#fff",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "96px !important",
    backgroundRepeat: "none",
    backgroundSize: "100% 100%",
  }),
  miniCardCenter: {
    height: "96px !important",
    objectFit: "cover !important",
    width: "96px !important",
    cursor: "pointer",
  },
  termsBox: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    color: "#fff",
    margin: "21px 0",
    display: isArabic ? "flex" : "block",
    flexDirection: "column",
    alignItems: isArabic ? "end" : "start",
  }),
  termsHead: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    marginBottom: "20px",
  }),
  termsPara: {
    width: "100%",
    margin: { xs: "17px 0", md: "17px 8px" },
    color: `rgb(180, 180, 180) !important`,
    fontFamily: `Inter`,
    fontSize: `14px`,
    fontWeight: "300",
    lineHeight: "normal",
    display: "flex",
    alignItems: "start",
    gap: "7px",
  },
  termsDot: {
    display: "flex",
    alignSelf: "start !important",
    justifySelf: "start !important",
    color: "#fff",
    height: "1px !important",
    verticalAlign: "start !important",
  },
  grayInfoBox: (isArabic) => ({
    boxSizing: "border-box",
    width: "calc(50vw - 48px)",
    maxWidth: "536px",
    height: "fit-content",
    display: { xs: "none", md: "flex" },
    flexDirection: "column",
    alignItems: isArabic ? "end" : "start",
    textAlign: isArabic ? "end" : "start",
    position: "relative",
    flexShrink: "0",
    borderRadius: "20px",
    background: "rgb(26, 31, 31)",
    color: "#fff",
    padding: "40px 30px",
    fontWeight: "100",
  }),
  musicalPara: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "normal",
  }),
  infoHeading: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    display: "flex",
    lineHeight: "normal",
    margin: "15px 0 16px",
    wordBreak: "break",
    background: "rgba(30, 30, 30, 1)",
    fontSize: "24px",
    fontWeight: "600",
  }),
  grayInfoPara: (isArabic) => ({
    width: "320px",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "19px",
    color: "var(--Gray, #B4B6B6)",
    marginBottom: "10px",
  }),
  locationLink: {
    textDecoration: "none",
    cursor: "pointer",
    color: "#B4B6B6",
    marginTop: "10px",
  },
  location: (isArabic) => ({
    fontSize: "14px",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontWeight: "400",
    padding: "8px 0",
    lineHeight: 1,
    color: "#B4B6B6",
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    alignItems: "center",
    gap: "10px",
  }),
  icon: {
    fontSize: "26px",
    color: "white",
    cursor: "pointer",
  },
  buyButton: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    color: "black",
    width: "100%",
    padding: "14px",
    fontSize: "18px",
    fontWeight: "800",
    borderRadius: "16px",
    background: "#FFBA83",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    margin: "30px 0 20px",
  }),
  grayButtonBox: (isArabic) => ({
    width: "100%",
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    justifyContent: "space-between",
    gap: "20px",
  }),
  grayButton: (isArabic) => ({
    width: "calc( 50% - 10px )",
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    padding: "14px",
    fontSize: "32px",
    flexShrink: "0",
    borderRadius: "16px",
    background: "var(--Secondary, #20353A)",
    gap: "8px",
    "&:hover": {
      backgroundColor: "var(--Secondary, #20353A)",
    },
  }),
  grayButtonTypo: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    color: "var(--White, #FFF)",
    textAlign: "center",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  }),
  hiddenButtonBox: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",

    background: "#000",
    display: { xs: "flex", md: "none" },
    border: "none",
    justifyContent: "center",
    overflow: "hidden",
  },
  hiddenButton: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    display: "flex",
    flexDirection: "column",
    lineHeight: "normal",
    fontStyle: "normal",
    width: "calc(100% - 24px)",
    maxWidth: "606px",
    margin: "20px",
    marginTop: 0,
    height: "60px",
    borderRadius: "16px",
    background: "#FFBA83",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  }),
  buyTypo: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    color: "var(--Dark, #060B0C)",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "700",
  }),
  priceTypo: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    color: "var(--Dark, #060B0C)",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "400",
  }),
  hiddenNavbar: {
    textAlign: "center",
    mt: { xs: "20px", md: 0 },
    position: "absolute",
    left: "0px",
    color: "#fff",
    display: { xs: "flex", md: "none" },
    justifyContent: "space-between",
    width: "100%",
    zIndex: 1,
    boxSizing: "border-box",
    p: "0 16px",
  },
  heading: (isArabic) => ({
    marginTop: "10px",
    color: COLORS.white,
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "24px",
    fontWeight: 800,
    textAlign: isArabic ? "end" : "start",
  }),
};
export default Style;
