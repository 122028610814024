import { COLORS } from "../../theme";

export const Style = {
  main: (isHome, isEmployee) => ({
    maxWidth: "1260px",
    width: "100%",
    height: "80px",
    display: !isEmployee
      ? { xs: isHome ? "flex" : "none", md: "flex" }
      : "none",
    padding: "15px 0",
    boxSizing: "border-box",
    margin: "0 auto",
  }),
  menuIcon: { height: "32px", width: "32px" },
  logoContainer: (isArabic) => ({
    cursor: "pointer",
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "150px",
  }),
  logoText: {
    textAlign: "center",
    fontFamily: "Abhaya Libre SemiBold",
    fontSize: "24px",
    fontWeight: "600",
    marginLeft: { xs: 0.5, xl: 1 },
  },
  searchBarContainer: {
    flexGrow: 1,
    display: { xs: "none", md: "flex" },
    alignItems: "center",
  },
  searchBar: { width: { xs: "320px", lg: "420px" }, height: "50px" },
  searchIcon: { color: COLORS.grey },
  optionsContainer: (user, isArabic) => ({
    width: user?.token ? "350px" : "450px",
    gap: "6px",
    display: { xs: "none", md: "flex" },
    flexDirection: isArabic ? "row-reverse" : "row",
    justifyContent: "space-between",
  }),
  mobOptionsContainer: { display: { xs: "flex", md: "none" } },
  menuDataContainer: {
    top: "64px",
    "& .MuiPaper-root": {
      top: "64px",
      borderRadius: "20px",
      background: "#20353A",
      color: COLORS.white,
    },
    "& .MuiList-root": {
      padding: 0,
    },
  },
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  menuDataWrapper: {
    py: "22px",
    px: "30px",
    width: "200px",
  },
  username: (isArabic) => ({
    padding: 0,
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "20px",
    fontWeight: 700,
  }),
  phoneNumber: (isArabic) => ({
    padding: 0,
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    fontWeight: 400,
  }),
  dropdownItemsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "20px",
  },
  dropdownItems: (isArabic) => ({
    padding: 0,
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "16px",
    fontWeight: 700,
  }),
  langCountry: {
    transform: `scaleX(1.0)`,
    cursor: "pointer",
    width: "50px",
    height: "50px",
  },
  langName: (isArabic) => ({
    cursor: "pointer",
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "var(--Light-BG, #1E2324)",
    color: "#B4B6B6",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    overflow: "hidden",
  }),
  avatar: {
    width: "50px",
    height: "50px",
    padding: "7px",
    boxSizing: "border-box",
    background: "#FFBA83",
    color: "#20353A",
  },
  loginButton: (isArabic) => ({
    width: "50%",
    maxWidth: "100px",
    height: "50px",
    borderRadius: "25px",
    color: "#20353A",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  }),
  navOptionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    cursor: "pointer",
    width: "160px",
    padding: "0 13px",
    boxSizing: "border-box",
  },
  navOptionNow: (isArabic) => ({
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  }),
  input: {
    width: "100%",
    display: "flex",
    textAlign: "end",
    "& .MuiInputBase-input": {
      width: "100%",
    },
  },
};
