import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Navbar, changeLanguage } from "./components";
import { DEFAULT_CATEGORY } from "./constant/data";
import AppRoutes from "./route";
import { getAllCategories } from "./services/data/categories";
import { getAllCountries } from "./services/data/countries";
import { getCountryCategoryEvents } from "./services/data/events";
import { getAllPages } from "./services/data/pages";
import { getAllSlides } from "./services/data/slides";
import { getUserProfile } from "./services/profile";
import { getCategories } from "./store/slice/categories";
import { getCountries } from "./store/slice/countries";
import { getEvents } from "./store/slice/events";
import { updateLoader } from "./store/slice/loading";
import { getPages } from "./store/slice/pages";
import { getSlides } from "./store/slice/slides";
import { updateUserData } from "./store/slice/user";
import { COLORS } from "./theme";
import { Toaster } from "react-hot-toast";
import { initializeMixpanel } from "./utils";

initializeMixpanel();
const App = () => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const theme = createTheme({
    palette: {
      primary: {
        light: COLORS.primary,
        main: COLORS.primary,
        dark: COLORS.primary,
      },
      secondary: {
        light: COLORS.secondary,
        main: COLORS.secondary,
        dark: COLORS.secondary,
      },
      black: {
        light: COLORS.black,
        main: COLORS.black,
        dark: COLORS.black,
      },
      white: {
        light: COLORS.white,
        main: COLORS.white,
        dark: COLORS.white,
      },
    },
  });
  const getUserData = async (token) => {
    getUserProfile(token)
      .then((res) => {
        dispatch(updateUserData({ ...res.data.data, token }));
        getData();
      })
      .catch((err) => {
        localStorage.removeItem("token");
        getData();
      });
  };
  const getData = async () => {
    let language = localStorage.getItem("language");
    changeLanguage(language);
    let category = localStorage.getItem("category");
    let countryId = localStorage.getItem("countryId");
    if (!!countryId?.length) {
      dispatch(updateUserData({ countryId: JSON.parse(countryId) }));
    }
    if (!category?.length) {
      localStorage.setItem("category", JSON.stringify(DEFAULT_CATEGORY));
    }
    if (!language?.length) {
      localStorage.setItem("language", "en");
      dispatch(updateUserData({ language: "en" }));
    }
    const slides = await getAllSlides();
    const countrires = await getAllCountries();
    const events = await getCountryCategoryEvents({
      page: 1,
      number: 12,
      country: countryId || 1,
      category: 0,
    });
    const categories = await getAllCategories({ page: 1, number: 10 });
    const pages = await getAllPages();
    Promise.all([slides, countrires, events, categories, pages])
      .then(
        ([
          slidesResponse,
          countriesResponse,
          eventResponse,
          categoriesResponse,
          pagesResponse,
        ]) => {
          dispatch(getSlides(slidesResponse?.data?.data));
          dispatch(getCountries(countriesResponse?.data?.data));
          dispatch(getEvents(eventResponse?.data?.data));
          dispatch(getCategories(categoriesResponse?.data?.data?.data));
          dispatch(getPages(pagesResponse.data.data));
          setLoading(false);
          dispatch(updateLoader({ loader: false }));
        }
      )
      .catch((err) => {
        setLoading(false);
        console.log("ERROR: ", err);
      });
  };
  useEffect(() => {
    if (!!token?.length) {
      getUserData(token);
    } else {
      getData();
    }
  }, []);
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Toaster position="top-right" reverseOrder={false} />
        <Box
          sx={{
            width: "100%",
            maxWidth: "1440px",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            m: "auto",
            boxSizing: "border-box",
            justifyContent: loading ? "center" : "initial",
            alignItems: loading ? "center" : "initial",
          }}
        >
          {!loading ? (
            <>
              <Navbar />
              <AppRoutes />
            </>
          ) : (
            <CircularProgress size={100} color="secondary" />
          )}
        </Box>
      </ThemeProvider>
    </Router>
  );
};

export default App;
