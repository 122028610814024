import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const events = createSlice({
  name: "events",
  initialState,
  reducers: {
    getEvents: (state, action) => {
      let data = action?.payload?.data?.filter((item) => item?.status);
      return { ...state, ...action?.payload, data };
    },
  },
});

export const { getEvents } = events.actions;

export const selectEventsData = (state) => state.events;

export default events.reducer;
