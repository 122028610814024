export const Style = {
  main: (isArabic) => ({
    boxSizing: "border-box",
    minHeight: "100%",
    width: "280px",
    display: "flex",
    flexDirection: "column",
    alignItems: isArabic ? "end" : "start",
    textAlign: isArabic ? "end" : "start",
    flexShrink: "0",
    background: "var(--Secondary, #20353A)",
    color: "#fff",
    padding: "30px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": { width: 0 },
  }),
  person: {
    boxSizing: "border-box",
    padding: "12px",
    width: "64px",
    height: "64px",
    flexShrink: "0",
    background: "#FFBA83",
    color: "#20353A",
    borderRadius: "50%",
  },
  drawerOptions: (isArabic) => ({
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textAlign: isArabic ? "end" : "left",
    "& .MuiListItemText-primary": {
      fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    }
  }),
  divider: {
    background: "#FFF",
    opacity: "0.1",
  },
  userName: (isArabic) => ({
    textTransform: "capitalize",
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    mt: "15px",
  }),
  userPhone: (isArabic) => ({
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    margin: "5px 0 0",
  }),
  drawerButton: (isArabic) => ({
    width: "220px",
    height: "50px",
    color: "#20353A",
    borderRadius: "16px",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    mb: "20px",
    mt: "5px",
  }),
  lists: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  listItemsContainer: (isArabic) => ({
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    width: "100%",
    gap: "10px",
    alignItems: "space-between",
  }),
  listItems: (isArabic) => ({
    cursor: "pointer",
    gap: "10px",
    display: "flex !important",
    flexDirection: isArabic ? "row-reverse" : "row",
    alignItems: "center",
    height: "fit-content",
    verticalAlign: "center",
  }),
  customIcons: {
    fontSize: "24px",
    width: "24px",
    height: "24px",
    borderRadius: 20,
  },
};
