import React from "react";
import { Box, Typography } from "@mui/material";
import { Style } from "./style";

const TicketDateTag = ({ date, month, sx = {}, dateSx = {}, monthSx = {} }) => {
  const dateSxNew = {...dateSx, lineHeight: 1.3}
  const monthSxNew = {...monthSx, lineHeight: 1.3}
  return (
    <Box
      sx={{
        ...Style.main,
        ...sx,
      }}
    >
      <Typography sx={dateSxNew}>{date}</Typography>
      <Typography sx={monthSxNew}>{month}</Typography>
    </Box>
  );
};

export default TicketDateTag;
