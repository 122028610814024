import { COLORS } from "../../theme";

export const Style = {
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "calc(100vh - 180px)",
    // flexGrow: 1,
    color: COLORS.white,
    gap: "16px",
    p: { xs: "0 16px", sm: 0 },
  },
  statusText: (isArabic) => ({
    fontWeight: 700,
    color: '#fff',
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "24px ",
  }),
  message: (isArabic) => ({
    fontWeight: 400,
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "16px",
    color: COLORS.grey,
    width: "100%",
    maxWidth: "335px",
    textAlign: "center",
  }),
  actionButton: (isArabic) => ({
    color: "var(--Dark, #060B0C)",
    textAlign: "center",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    color: COLORS.black,
    width: "100%",
    maxWidth: { xs: "600px", sm: "300px" },
    height: "60px",
    borderRadius: "16px",
    position: { xs: "absolute", sm: "static" },
    width: { xs: "calc(100vw - 40px)", sm: "100%" },
    bottom: "120px",
    left: "20px",
  }),
  backButton: (isArabic) => ({
    color: "var(--Dark, #060B0C)",
    textAlign: "center",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    color: COLORS.white,
    width: "100%",
    maxWidth: { xs: "600px", sm: "300px" },
    height: "60px",
    borderRadius: "16px",
    position: { xs: "absolute", sm: "static" },
    width: { xs: "calc(100vw - 40px)", sm: "100%" },
    bottom: "40px",
    left: "20px",
  }),
};
