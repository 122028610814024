import axios from "axios";

const getCountryEvents = async (countryId) => {
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/country-events/${countryId}`,
  });
};

const getSearchedEvent = async (props) => {
  const { searchKey, page = 1, number = 12 } = props;
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/search-events?name=${searchKey}&page=${page}&number=${number}`,
  });
};

const getSingleEvent = async (event, token) => {
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/event/${event}?token=${token}`,
  });
};
const getCountryCategoryEvents = async (props) => {
  const { page, number, country, category, searchKey } = props;
  return await axios({
    method: "get",
    url: `${
      process.env.REACT_APP_BASE_URL
    }/events-filter?page=${page}&number=${number}&country=${country}&category=${category}&name=${
      searchKey || ""
    }`,
  });
};
export {
  getCountryCategoryEvents,
  getCountryEvents,
  getSearchedEvent,
  getSingleEvent,
};
