import { COLORS, IMAGES } from "../../theme";

export const Style = {
  mainContainer: {
    width: { xs: "100%", sm: "calc(50% - 10px)", md: "calc(33% - 10px)" },
    maxWidth: { xs: "600px", sm: "450px", md: "385px" },
  },
  main: (isArabic) => ({
    backgroundImage: `url(${IMAGES.ticketBlackBorder})`,
    transform: isArabic ? 'scaleX(-1)' : 'auto',
    maxWidth: { xs: "600px", sm: "450px", md: "385px" },
    width: "100%",
    height: "120px",
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    px: "16px",
    boxSizing: "border-box",
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 110%",
    cursor: "pointer",
  }),
  ticketDateTag: (isArabic) => ({
    transform: isArabic ? 'scaleX(-1)' : 'auto',
    top: "-10px",
    left: "26px",
    width: "44px",
    height: "56px",
  }),
  date: (isArabic) => ({
    fontSize: "18px",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontWeight: 800,
  }),
  month: (isArabic) => ({
    fontSize: "10px",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontWeight: 700,
  }),
  img: {
    width: "130px",
    height: "100px",
    borderRadius: "12px",
  },
  contentContainer: (isArabic) => ({
    transform: isArabic ? 'scaleX(-1)' : 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: isArabic ? 'end' : 'start',
    marginLeft: { xs: "20px", sm: "20px" },
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    width: "100%",
  }),
  typeBadge: {
    background: "linear-gradient(90deg, #AD8026 0%, #ECD273 100%)",
    color: COLORS.white,
    width: "40PX",
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "600",
    borderRadius: "20px",
    fontSize: "12px",
    height: "30px",
  },
  tittle: {
    fontSize: { xs: "13px", sm: "13px" },
    fontWeight: "700",
    marginY: "3px",
    mt: "10px",
    lineHeight: 1.8,
  },
  price: {
    fontSize: { xs: "13px", sm: "14px" },
    fontWeight: "700",
    lineHeight: "1.3",
  },
  currency: {
    fontSize: { xs: "10", sm: "11px" },
    fontSize: { xs: "10px", sm: "12px" },
    fontWeight: "600",
    lineHeight: "1.3",
  },
};
