import { COLORS } from "../../theme";

export const Style = {
  main: (scroll, src, isOne) => ({
    scrollLeft: scroll || 0,
    position: "relative",
    width: {
      xs: isOne ? "100vw" : "calc( 100vw - 80px )",
      sm: "calc( 50% - 20px )",
      md: "calc( 33% - 22px )",
      lg: "calc( 25% - 27px )",
    },
    height: { xs: "500px", sm: "412px" },
    minWidth: "200px",
    background: `url('${src}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    borderRadius: "20px",
    display: "flex",
  }),

  card: () => ({
    height: { xs: "500px", sm: "412px" },
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #20353A 100%)",
    borderRadius: "18px",
    boxSizing: "border-box",
    width: "100%",
    px: { xs: "10px", sm: "20px" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    cursor: "pointer",
  }),
  ticketDateTag: (isArabic) => ({
    top: "-12px",
    left: isArabic ? "none" : "20px",
    right: isArabic ? "20px" : "none",
    width: "64px",
    height: "88px",
  }),
  date: (isArabic) => ({
    fontSize: "24px",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    padding: 0,
    lineHeight: "normal",
    mt: "12px",
  }),
  month: (isArabic) => ({
    fontSize: "14px",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    lineHeight: 1.3,
    fontWeight: 700,
    padding: 0,
  }),
  cardContent: (isArabic) => ({
    color: COLORS.white,
    px: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: isArabic ? "end" : "start",
    textAlign: isArabic ? "end" : "start",
    gap: "12px",
  }),
  eventName: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: 1,
  }),
  startPeriod: (isArabic) => ({
    borderRadius: "20px",
    background: COLORS.white,
    py: 1,
    px: { xs: 0.8, sm: 3 },
    color: COLORS.black,
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    lineHeight: 1.3,
    fontSize: "14px",
    fontWeight: 800,
    width: "fit-content",
    textAlign: "end",
  }),
  location: (isArabic) => ({
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    fontSize: "14px",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#B4B6B6",
  }),
  name: (isArabic) => ({
    fontSize: "14px",
    display: "flex",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontWeight: 400,
  }),
  favouriteIcon: (isArabic) => ({
    position: "absolute",
    top: 10,
    right: isArabic ? "none" : 10,
    left: isArabic ? 10 : "none",
  }),
};
