import axios from "axios";

const getAllPages = async () => {
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/pages`,
  });
};
const getSinglePage = async (page_slug) => {
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/page/${page_slug}`,
  });
};
export { getAllPages, getSinglePage };
