import { COLORS } from "../../theme";

export const Style = {
  checkOutModal: {
    width: "100%",
    maxWidth: "520px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    margin: "25px auto",
    padding: { xs: 0, sm: "0 20px" },
  },
  timerBox: {
    mt: { xs: "60px", md: 0 },
    width: { xs: "calc(100% - 40px)", sm: "100%" },
    borderRadius: "16px",
    background: "var(--Secondary, #20353A)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    boxSizing: "border-box",
    padding: "13px 15px",
  },
  timerPara: (isArabic) => ({
    color: "var(--White, #FFF)",
    textAlign: "center",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    opacity: "0.7",
  }),
  timerTime: (isArabic) => ({
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  }),
  divider: {
    color: "#fff !important",
    width: "100%",
    margin: "0 !important",
  },
  paymentBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    borderRadius: "20px",
    marginTop: "20px",
    boxSizing: "border-box",
    background: { xs: "none", sm: "rgba(30, 30, 30, 0.9)" },
    padding: { xs: "12px 15px", sm: "25px 30px" },
  },
  paymentHead: (isArabic) => ({
    display: { xs: "none", sm: "flex" },
    justifyContent: isArabic ? "end" : "start",
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    marginBottom: "15px",
  }),
  paymentChildBox: (isArabic) => ({
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "7px 0",
    margin: "10px 0 !important",
  }),
  paymentChildHead: (isArabic) => ({
    color: "var(--Gray, #B4B6B6)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: { xs: "14px", sm: "16px" },
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  }),
  paymentChildInfo: (isArabic) => ({
    color: "var(--White, #FFF)",
    textAlign: "right",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: { xs: "14px", sm: "17px" },
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "normal",
  }),
  paymentChildTime: (isArabic) => ({
    color: "var(--White, #FFF)",
    textAlign: "right",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  }),
  locationBox: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  paymentAmountBox: (isArabic) => ({
    display: "flex",
    height: "fit-content",
    alignItems: "end",
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    gap: "5px",
  }),
  paymentAmount: (isArabic) => ({
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: { xs: "16px", sm: "22px" },
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  }),
  discount: (isArabic) => ({
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  discountButton: (isArabic) => ({
    color: "var(--Dark, #060B0C)",
    textAlign: "center",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    height: "55px",
    flexShrink: "0",
    borderRadius: "16px",
    background: "#FFBA83",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    marginLeft: isArabic ? 0 : "10px",
    marginRight: isArabic ? "10px" : 0,
  }),
  discountInput: (isArabic) => ({
    color: COLORS.grey,
    background: "#1E2324",
    width: "100%",
    borderRadius: "12px",
    border: "none",
    height: "55px",
    borderBottom: "none !important",
    "& .MuiOutlinedInput-input": {
      textAlign: isArabic ? "end" : "start",
      fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    },
    "&::placeholder": {
      color: COLORS.white,
    },
    "& #input-with-icon-adornment": {
      textAlign: isArabic ? "end" : "start",
      padding: "10px",
    },
    "&::before": {
      borderBottom: "none !important",
    },
    "&::after": {
      borderBottom: "none !important",
    },
  }),
  discountInputLabel: {
    color: COLORS.white,
  },
  discountMessage: () => ({
    color: "red",
  }),
  selectOrangeBox: (isArabic) => ({
    border: "2px solid #FFBA83",
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    boxSizing: "border-box",
    borderRadius: "12px",
    padding: "15px 26px",
    background: "var(--Light-BG, #1E2324)",
    margin: "12px 0",
    cursor: "pointer",
  }),
  selectGrayBox: (isArabic) => ({
    cursor: "pointer",
    background: "var(--Light-BG, #1E2324)",
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "calc( 100% - 2px )",
    boxSizing: "border-box",
    borderRadius: "12px",
    padding: "17px 25px",
    margin: "12px 0",
  }),
  Heading: (isArabic) => ({
    color: COLORS.white,
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    display: "flex",
    width: "100%",
    justifyContent: isArabic ? "end" : "start",
    margin: { xs: "35px 0 15px", sm: "25px 0 15px !important" },
  }),
  grayBox: {
    width: "100%",
    borderRadius: "16px",
    background: { sm: "none", xs: "rgba(30, 30, 30, 0.9)" },
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    boxSizing: "border-box",
    padding: "0 15px",
  },
  flex: (isArabic) => ({
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    alignItems: "center",
    gap: "8px",
    color: COLORS.white,
  }),
  orangeIcon: {
    fontSize: "40px",
    fill: "#FFBA83",
  },
  grayIcon: {
    fontSize: "40px",
    fill: "rgb(60, 60, 60)",
  },
  selectBox: (isArabic) => ({
    textAlign: isArabic ? "end" : "start",
    width: "100%",
  }),
  input: (isArabic) => ({
    color: COLORS.grey,
    background: "#1E2324",
    width: "100%",
    borderRadius: "12px",
    "& .MuiOutlinedInput-input": {
      textAlign: isArabic ? "end" : "start",
      fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    },
  }),
  confirm: (isArabic) => ({
    color: "var(--Gray, #B4B6B6)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    marginTop: "22px",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    // textAlign: 'end'
  }),
  terms: (isArabic) => ({
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    margin: "3px 0",
    // textAlign: 'end'
  }),
  confirmButton: (isArabic) => ({
    color: "var(--Dark, #060B0C)",
    textAlign: "center",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    marginTop: "18px",
    height: "60px",
    flexShrink: "0",
    borderRadius: "16px",
    background: "#FFBA83",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    width: "100%",
    // position: { xs: "absolute", sm: "static" },
    // width: { xs: "calc(100% - 40px)", sm: "100%" },
    // bottom: "20px",
    // left: "20px",
  }),
  checkOutHeading: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    letterSpacing: "0.00938em",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  }),
};
