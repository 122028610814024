import { COLORS } from "../../../theme";

export const CustomStyle = {
  input: {
    width: "100%",
    margin: 0,
    fontWeight: "bold",
    color: "#fff",
    borderRadius: "8px",
    height: "52px",
    fontSize: "16px",
    lineHeight: "18px",
    background: "#1E2324",
  },
  label: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontWeight: 600,
    fontSize: "12px",
    color: COLORS.white,
    opacity: "0.6",
    width: "100%",
    display: "flex",
    alignItems: "start",
  }),
  button: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    background: "#1E2324",
  },
  dropDown: {
    width: "calc(100% - 65px)",
    background: "#1E2324",
    position: "fixed",
    textAlign: "start",
    color: COLORS.white,
    paddingTop: "20px",
    boxSizing: "border-box",
    overflowY: "auto",
  },
  containerStyle: {
    overflow: "hidden",
    boxSizing: "border-box",
  },
  searchStyle: {
    boxSizing: "border-box",
    width: "100%",
    height: "40px",
    padding: "0 9px",
    position: "absolute",
    top: -24,
    left: -6,
    background: "aqua !important",
  },
  inputProps: {
    name: "phone",
    required: true,
    autoFocus: true,
  },
  verificationOtpContiner: {
    background: "#1E2324",
    display: "flex",
    padding: 1,
    gap: "10px",
    width: 'calc(100% - 16px)',
    alignItems: "center",
  },
  verificationOtpText: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    opacity: "0.7",
    fontSize: "12px",
    width: "100% !important",
    textAlign: "left",
  }),
  whatsAppIcon: { width: "32px", height: "32px" },
};
