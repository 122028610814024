import axios from "axios";

const getOldTickets = async (props) => {
  const { token, page, number } = props;
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/my-old-tickets?page=${page}&number=${number}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getUpcomingTickets = async (props) => {
  const { token, page, number } = props;
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/my-upcoming-tickets?page=${page}&number=${number}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getTicketDetails = async (token, id) => {
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/ticket-details/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export { getOldTickets, getUpcomingTickets, getTicketDetails };
