const DEFAULT_CATEGORY = {
  id: 0,
  sort: 0,
  slug: "all",
  status: true,
  translation: {
    en: {
      id: 1,
      name: "All",
    },
    ar: {
      id: 2,
      name: "الجميع",
    },
  },
};
export { DEFAULT_CATEGORY };
