import { COLORS, IMAGES } from "../../../theme";

export const Style = {
  main: {
    maxWidth: { xs: "250px", sm: "325px" },
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center !important",
    height: "400px",
  },
  firstChild: {
    display: { xs: "none", sm: "flex" },
    gap: "20px",
    alignItems: "baseline",
  },
  icon: { width: "28px", height: "28px", cursor: "pointer" },
  bgContainer: {
    boxSizing: "border-box",
    height: { xs: "530px", sm: "630px" },
    width: "100%",
    maxWidth: { xs: "250px", sm: "325px" },
  },
  heading: (isArabic) => ({
    ml: "45px",
    color: "var(--White, #FFF)",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    mb: "24px",
    width: "100%",
    display: { xs: "flex", sm: "none" },
  }),
  bgOrangeContainer: {
    pt: { xs: "30px", sm: "40px" },
    px: { xs: "30px", sm: "40px" },
    color: COLORS.black,
    backgroundImage: `url('${IMAGES.ticketBgOrange}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
  },
  ticketId: {
    lineHeight: { xs: "30px", sm: "40px" },
    fontSize: { xs: "12px", sm: "16px" },
    fontWeight: "800",
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url('${IMAGES.ticketBgWhite}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    boxSizing: "border-box",
    p: { xs: "25px 10px", sm: "25px" },
    textAlign: "left",
    color: COLORS.black,
    fontSize: "14px",
    position: "relative",
    width: "100% !important",
  },
  contentWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  ticketDetailContainer: {
    display: "inline-flex",
    flexDirection: "column",
    gap: "5px",
  },
  eventName: (isArabic) => ({
    fontSize: { xs: "20px", sm: "24px" },
    fontWeight: 700,
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    width: "100%",
    maxWidth: "170px",
  }),
  ticketDateTag: {
    position: "initial",
    width: "64px",
    height: "88px",
  },
  date: (isArabic) => ({
    fontSize: "24px",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontWeight: 800,
  }),
  month: (isArabic) => ({
    fontSize: "14px",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontWeight: 700,
  }),
  userDetailContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    mt: "3px",
    width: "100%",
    wordBreak: "break-all",
  },
  organizationName: (isArabic) => ({
    mt: { xs: "16px", sm: "-30px" },
    color: COLORS.grey,
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "12px",
    fontWeight: 600,
  }),
};
