import React, { useEffect, useState } from "react";
import ModalWrapper from "../../ModalWrapper";
import { Box, Typography } from "@mui/material";
import CustomButton from "../../CustomButton";
import OtpInput from "react-otp-input";
import { Style } from "../style";
import { CustomStyle } from "./style";
import { loginStepOne, loginStepTwo } from "../../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateUserData } from "../../../store/slice/user";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Profile from "../Profile";
import { COLORS } from "../../../theme";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const VerifyAccountModal = ({ open, setOpen, data, setData }) => {
  const dispatch = useDispatch();
  const { language } = useSelector(selectUser);
  const [code, setCode] = useState("");
  const [openCompleteProfileModal, setCompleteProfileModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState({ minutes: 0, seconds: 59 });
  const { t } = useTranslation();
  const handleSubmit = async () => {
    setLoading(true);
    await loginStepTwo({ code, uuid: data.uuid })
      .then((res) => {
        setCode("");
        toast.success("Verified Successfully");
        setLoading(false);
        setOpen(false);
        if (data.isNewUser) {
          setCompleteProfileModal(true);
        } else {
          localStorage.setItem("token", res.data.data.token);
          dispatch(
            updateUserData({
              ...res.data.data.account,
              token: res.data.data.token,
            })
          );
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.data?.message || "Verification failed"
        );
        setLoading(false);
      });
  };
  const handleResend = async (type) => {
    if (timePeriod?.seconds === 0 && timePeriod?.minutes === 0) {
      setTimePeriod({
        seconds: 59,
        minutes: 0,
      });
      await loginStepOne({ mobile: data.mobile, type })
        .then((res) => {
          setLoading(false);
          setData({ ...res.data.data, mobile: data.mobile });
          toast.success("OTP has been resent");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.data?.message);
        });
    }
  };

  useEffect(() => {
    if (open) {
      setTimePeriod({
        seconds: 59,
        minutes: 0,
      });
    }
    let timer = setInterval(() => {
      setTimePeriod((prev) => {
        if (!(prev?.seconds === 0 && prev?.minutes === 0)) {
          return {
            seconds: prev?.seconds === 0 ? 60 : prev?.seconds - 1,
            minutes: !prev?.seconds % 60 ? prev?.minutes - 1 : prev?.minutes,
          };
        } else {
          return {
            seconds: 0,
            minutes: 0,
          };
        }
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [open]);
  const isArabic = language === 'ar'
  return (
    <Box>
      <ModalWrapper open={open} setOpen={setOpen} crossIcon>
        <Typography
          sx={{
            display: "flex",
            textAlign: "center",
            mt: { xs: "20px", sm: 0 },
          }}
        >
          <Typography
            component={"span"}
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              left: "10px",
              display: { xs: "flex", sm: "none" },
            }}
          >
            <ArrowBackIcon />
          </Typography>

          <Typography component={"span"} sx={Style.heading(isArabic)}>
            {t("verify_account_modal.verify_account")}
          </Typography>
        </Typography>
        <Typography sx={Style.subHeading(isArabic)}>
          {t("verify_account_modal.enter_otp")}
        </Typography>
        <Typography sx={CustomStyle.number(isArabic)}>+{data?.mobile}</Typography>
        <OtpInput
          value={code}
          isInputNum
          onChange={setCode}
          numInputs={4}
          inputType={"number"}
          renderInput={(props) => (
            <input {...props} style={CustomStyle.otpInput} />
          )}
        />
        <Typography sx={CustomStyle.getCode(isArabic)}>
          {t("verify_account_modal.not_get_code")}
        </Typography>
        <Typography sx={CustomStyle.getCode(isArabic)}>
          {t("verify_account_modal.send_again")} {timePeriod?.minutes} :{" "}
          {timePeriod?.seconds}
        </Typography>
        <Typography sx={CustomStyle.getCode(isArabic)}>
          {t("verify_account_modal.resend_otp")}{" "}
          <span
            style={{
              ...CustomStyle.resend(isArabic),
              color: !(timePeriod?.seconds === 0 && timePeriod?.minutes === 0)
                ? COLORS.grey
                : COLORS.secondary,
              cursor: !(timePeriod?.seconds === 0 && timePeriod?.minutes === 0)
                ? "none"
                : "pointer",
            }}
            onClick={() => handleResend("sms")}
          >
            {t("verify_account_modal.sms")}
          </span>
        </Typography>
        <Typography sx={CustomStyle.getCode(isArabic)}>
          {t("verify_account_modal.or")}{" "}
          <span
            style={{
              ...CustomStyle.resend(isArabic),
              color: !(timePeriod?.seconds === 0 && timePeriod?.minutes === 0)
                ? COLORS.grey
                : COLORS.secondary,
              cursor: !(timePeriod?.seconds === 0 && timePeriod?.minutes === 0)
                ? "none"
                : "pointer",
            }}
            onClick={() => handleResend("voice")}
          >
            {t("mobile_no_modal.call")}
          </span>
        </Typography>
        <CustomButton
          disable={loading || code.length < 4}
          onClick={handleSubmit}
          buttonText={t("verify_account_modal.verify")}
          color="secondary"
          sx={Style.button}
          loading={loading}
        />
      </ModalWrapper>
      <Profile
        open={openCompleteProfileModal}
        setOpen={setCompleteProfileModal}
        data={data}
      />
    </Box>
  );
};

export default VerifyAccountModal;
