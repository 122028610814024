import React from "react";
import { CustomDivider, DropDownWrapper } from "../..";
import { Style } from "./style";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../../services/profile";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser } from "../../../store/slice/user";
import { Box, MenuItem } from "@mui/material";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const MainMenu = ({ anchorEl, id, onClick, onClose, setOpenProfile }) => {
  const { language } = useSelector(selectUser);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const handleLogout = async () => {
    try {
      await logoutUser(user.token);
      dispatch(logout());
      toast.success("Sign Out Successfully");
    } catch (err) {
      return err;
    }
  };
  const isArabic = language === 'ar'
  return (
    <DropDownWrapper
      anchorEl={anchorEl}
      id={id}
      keepMounted
      open={!!anchorEl}
      onClick={onClick}
      onClose={onClose}
    >
      <MenuItem sx={Style.username(isArabic)}>{user?.name}</MenuItem>
      {!!user?.mobile && <MenuItem sx={Style.phoneNumber(isArabic)}>+{user?.mobile}</MenuItem>}
      <CustomDivider />
      <Box sx={Style.dropdownItemsContainer}>
        <MenuItem sx={Style.dropdownItems(isArabic)} onClick={() => setOpenProfile(true)}>
          {t("drawer.my_profile")}
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/my-tickets")}
          sx={Style.dropdownItems(isArabic)}
        >
          {t("drawer.my_tickets")}
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/my-favorites")}
          sx={Style.dropdownItems(isArabic)}
        >
          {t("drawer.my_favorites")}
        </MenuItem>
      </Box>
      <CustomDivider />
      <MenuItem sx={Style.dropdownItems(isArabic)} onClick={handleLogout}>
        {t("drawer.sign_out")}
      </MenuItem>
    </DropDownWrapper>
  );
};

export default MainMenu;
