import { COLORS } from "../../theme";

export const Style = {
  main: {
    mt: "25px",
  },
  contentContainer: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: { xs: "calc( 100% - 40px )", md: "calc( 100% - 45px )" },
    left: { xs: 0, sm: "0" },
    color: COLORS.white,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    gap: { xs: 0, lg: 1.5 },
    justifyContent: "center",
    alignItems: "center",
    mt: "20px",
  },
  content: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: { xs: "10px", sm: "12px", md: "14px" },
    fontWeight: 600,
    textAlign: "center",
  }),
  bgImg: { width: { xs: "125px", sm: "165px", md: "200px" } },
  date: {
    fontFamily: "Inter",
    fontSize: { xs: "18px", lg: "20px" },
    fontWeight: 600,
    textAlign: "center",
    display: { xs: "none", md: "flex" },
  },
  hiddenDateBox: {
    position: "absolute",
    bottom: "53px",
    display: { xs: "flex", md: "none" },
    width: "100%",
    justifyContent: "space-between",
    alignItems: "end",
    padding: "0 12px",
  },
  dateBox: (isArabic) => ({
    color: "#FFF",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "normal",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  }),
  dateNo: (isArabic) => ({
    textAlign: "center",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  }),
  carouselBox: {
    minHeight: "140px",
    position: "relative",
    cursor: "pointer",
  },
  carouselImg: {
    width: "100%",
    height: { xs: "150px", md: "280px" },
    objectFit: "cover",
    minHeight: "190px",
    borderRadius: "12px",
  },
  image: {
    width: { xs: "200px", lg: "200px" },
    height: { xs: "80px", lg: "100px" },
  },
};
