import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Typography } from "@mui/material";
import React from "react";
import { FaRegMap } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { convertDate } from "../../../lib/helper";
import { selectUser } from "../../../store/slice/user";
import IconCircle from "../../IconCircle";
import ModalWrapper from "../../ModalWrapper";
import TicketDateTag from "../../TicketDateTag";
import { Style } from "./style";
import { useTranslation } from "react-i18next";

const TicketQR = ({ open, setOpen, data }) => {
  const { t } = useTranslation();
  const { day, month } = convertDate(data?.ticket?.event?.event_date, t);
  const { language, email, mobile, name } = useSelector(selectUser);
  const isArabic = language === "ar";
  return (
    <ModalWrapper open={open} crossIcon={false} sx={Style.main}>
      {/* Large Screen */}
      <Box sx={Style.firstChild}>
        <Box
          onClick={() => window.open(data?.ticket?.event?.map)}
          component={"div"}
        >
          <IconCircle icon={<FaRegMap style={Style.icon} />} />
        </Box>
        <Box onClick={() => setOpen(false)} component={"div"}>
          <IconCircle icon={<RxCross2 style={Style.icon} />} />
        </Box>
      </Box>
      {/* Large Screen */}
      {/* Small Screen */}
      <Typography
        sx={{
          display: "flex",
          textAlign: "start",
          justifyContent: "start",
          mt: { xs: "20px", sm: 0 },
          width: "260px",
          position: "relative",
        }}
      >
        <Typography
          component={"span"}
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            left: "10px",
            display: { xs: "flex", sm: "none" },
          }}
        >
          <ArrowBackIcon />
        </Typography>
        <Typography component={"span"} sx={Style.heading(isArabic)}>
          Ticket Details
        </Typography>
        <Typography
          onClick={() => window.open(data?.ticket?.event?.map)}
          component={"span"}
          sx={{
            position: "absolute",
            right: "10px",
            gap: "10px",
            display: { xs: "flex", sm: "none" },
            cursor: "pointer",
          }}
        >
          <FaRegMap style={Style.icon} />
        </Typography>
      </Typography>
      {/* Small Screen */}

      <Box sx={Style.bgContainer}>
        <Box sx={Style.bgOrangeContainer}>
          {data?.qr_code?.full_qr_code ? <Box
            component="img"
            src={data?.qr_code?.full_qr_code}
            sx={{ width: "100%", maxWidth: { xs: "185px", sm: "220px" } }}
          />: <Typography sx={Style.ticketId}>No QR Found</Typography>}
          <Typography sx={Style.ticketId}>{data?.id}</Typography>
        </Box>

        <Box sx={Style.contentDiv}>
          <Box sx={Style.contentWrapper}>
            <Box sx={Style.ticketDetailContainer}>
              <Typography fontFamily="Inter">
                {data?.ticket?.event?.category?.translation?.[language]?.name}
              </Typography>
              <Typography sx={Style.eventName(isArabic)}>
                {data?.ticket?.event?.translation?.[language]?.name}
                {/* <br />  */}
              </Typography>
              <Typography>{data?.ticket?.event?.event_time}</Typography>
            </Box>

            <TicketDateTag
              date={day}
              month={month}
              sx={Style.ticketDateTag}
              dateSx={Style.date(isArabic)}
              monthSx={Style.month(isArabic)}
            />
          </Box>
          <Typography>
            {data?.ticket?.event?.translation?.[language]?.address}
          </Typography>

          <Box sx={Style.userDetailContainer}>
            <Typography>{name}</Typography>
            <Typography>{email}</Typography>
            <Typography>+{mobile}</Typography>
          </Box>
        </Box>
      </Box>

      <Typography sx={Style.organizationName(isArabic)}>
        Ticket By OASI 7 Agency
      </Typography>
    </ModalWrapper>
  );
};

export default TicketQR;
